import React, { useEffect, useState } from 'react'

import './header.css'
import { ReactSVG } from 'react-svg'
import TsLogo from '../assets/TSLogo.svg'

import TsLogoWhite from '../assets/TSLogoWhite.svg'

import BagIcon from '../assets/CartBag.svg'
import BagIconWhite from '../assets/CartBagWhite.svg'

import TSMenu from '../assets/TSMenu.svg'

import { Link, useNavigate } from 'react-router-dom'

import { UserAuth } from '../context/AuthContext'
import { motion } from "framer-motion"
import { computeHeadingLevel } from '@testing-library/react'
import { analytics } from '../firebase'
import { logEvent } from 'firebase/analytics';

const Header = (props) => {
  const [isOnHome, setIsOnHome] = useState(true);
  const [colorstyle, setColorStyle] = useState({ color: '#038961' });
  const [logoSvg, setLogoSvg] = useState(TsLogo);
  const [cartSvg, setCartSvg] = useState(BagIcon);
  const [menuOpen, setMenuOpen] = useState(false);

  const page = props.page;

  console.log(page);

  const navigate = useNavigate()
  const { user } = UserAuth()

  function changeToProducts() {
    logEvent(analytics, "Header_Products");
    navigate('/products');
  }

  function changeToHome() {
    logEvent(analytics, "Header_Home");
    navigate('/');
  }

  function changeToAbout() {
    logEvent(analytics, "Header_About");
    navigate('/about');

  }

  function changeToAccount() {
    logEvent(analytics, "Header_Account");
    navigate('/account');
  }

  useEffect(() => {
    if (page === "home") {
      setLogoSvg(TsLogoWhite);
      setCartSvg(BagIconWhite);
      setColorStyle({ color: 'white' });
    } else if (page === "about") {
      setLogoSvg(TsLogoWhite);
      setCartSvg(BagIconWhite);
      setColorStyle({ color: 'white' });
    } else if (page === "blog") {
      setLogoSvg(TsLogo);
      setCartSvg(BagIcon);
      setColorStyle({ color: 'black' });
    } else {
      setLogoSvg(TsLogoWhite);
      setCartSvg(BagIconWhite);
      setColorStyle({ color: 'white' });
    }

  }, [page])

  return (
    <div className="header__everything">

      {menuOpen && (
        <div className="header__menuopen">
          <div className="header__menu__item"
            onClick={changeToHome}>
            Home
          </div>

          <div className="header__menu__item"
            onClick={changeToProducts}
          >
            Products
          </div>

          <div className="header__menu__item"
            onClick={changeToAbout}
          >
            About
          </div>

          <div className="header__menu__item"
            onClick={changeToAccount}
          >
            Account
          </div>
        </div>
      )}

      <div className='header__container'
        style={colorstyle}>

        <div className="header__mobilecontents"
          onClick={() => { setMenuOpen(!menuOpen) }}
        >
          <div className="hamburger-menu">
            <div className={`hamburger ${menuOpen ? 'open' : ''}`}>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>


        <div className="header__logo"
          onClick={changeToHome}
        >
          <ReactSVG fill='red' stroke='green' src={logoSvg} />
        </div>

        <div className="header__items">
          <div className="header__itemcontainer">
            <div className="header__item"
              onClick={changeToHome}
            >
              Home
            </div>

            <div className="header__item"
              onClick={changeToProducts}
            >
              Products
            </div>

            <div className="header__item"
              onClick={changeToAbout}
            >
              About
            </div>

            <div className="header__item"
              onClick={changeToAccount}>
              Account
            </div>
          </div>
        </div>

        <div className="header__cartlogo">
          <ReactSVG src={cartSvg} />

        </div>
      </div>
    </div>
  )
}

export default Header