import React from 'react'
import Header from './header/Header'
import Salepage from './salepage/Salepage'
import { Route, Routes } from 'react-router-dom'
import { AuthContextProvider } from './context/AuthContext';


import './index.css'
import Home from './pages/Home'
import Aurora from './pages/Aurora'
import Products from './pages/Products'
import SignUp from './signup/SignUp'
import SignUpPage from './signup/SignUpPage'
import Accountpage from './accountpage/Accountpage';
import ProtectedRoute from './ProtectedRoute';
import PluginForm from './plugform/PluginForm';
import ProtectedPlugin from './protectedplugin/ProtectedPlugin';
import PurchaseRedirect from './purchase/PurchaseRedirect';
import PurchaseThanks from './purchase/PurchaseThanks';
import SignInPagePlugin from './signin/SignInPagePlugin';
import Licenses from './licenses/Licenses';
import NewLicRefresh from './licenses/NewLicRefresh';
import CreateNewLic from './licenses/CreateNewLic';
import CompletedReload from './licenses/CompletedReload';
import AboutPage from './pages/AboutPage';
import AdminPage from './adminpage/AdminPage';
import PluginFailed from './pluginfailed/PluginFailed';
import ProtectedPluginFailed from './protectedplugin/ProtectedPluginFailed';
import ContactUsForm from './pages/Contact';




import SignIn from './signin/SignIn';

import Checkout from './testpages/TestShopRapyd';
import CheckoutPage from './checkoutpage/CheckoutPage';

import SignInPage from './signin/SignInPage';
import ContactThanks from './pages/ContactThanks';
import ResetPassword from './signin/ResetPassword';
import VerifyEmail from './signup/VerifyEmail';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import ResetInfo from './signin/ResetInfo';
import ResetField from './signin/ResetField';
import ProtectedRouteIceland from './ProtectedRouteIceland';
import { FastSpringProvider } from './checkoutpage/FastSpringContext';
import FastSpringCheckout from './checkoutpage/FastSpringCheckout';
import SoundbirdTech from './blog/SoundbirdTech';


const App = () => {
  return (
    <div>
      <div class="noise"></div>
      <AuthContextProvider>
        <FastSpringProvider>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/products' element={<Products />}></Route>
            <Route path='/aurora' element={<Aurora />} />

            <Route path='/about' element={<AboutPage />} />
            <Route path='/contact' element={<ContactUsForm />} />
            <Route path='/contact/thanks' element={<ContactThanks />} />
            <Route path='/signup/:navigation' element={<SignUpPage />} />
            <Route path='/signin/plugin/:reload' element={<SignInPagePlugin />} />
            <Route path='/signin/plugin' element={<SignIn />} />
            <Route path='/plugin/:reload/failed' element={<PluginFailed />} />


            <Route path="/signin" element={<SignInPage />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/verify" element={<VerifyEmail />} />
            <Route path="/resetinfo" element={<ResetInfo />} />
            <Route path="/resetpassword" element={<ResetField />} />


            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />

            <Route path="*" element={<Aurora />} />

            <Route path="/fastspring" element={<FastSpringCheckout />} />

            <Route path="/soundbird" element={<SoundbirdTech />} />

            {/*<Route to="/files/Chris_Warren.pdf" target="_blank" download>Download</Route>*/}

            <Route path='/auroraauth' element={
              <ProtectedRoute>
                <PluginForm />
              </ProtectedRoute>} />

            <Route path="/checkout" element={
              <ProtectedRoute>
                <CheckoutPage />
              </ProtectedRoute>} />

            <Route path="/checkout/:country" element={
              <ProtectedRouteIceland>
                <CheckoutPage />
              </ProtectedRouteIceland>} />


            <Route path='/purchase/:name/succesful' element={
              <ProtectedRoute>
                <PurchaseRedirect />
              </ProtectedRoute>} />

            <Route path='/thanksforpurchase/:product' element={
              <ProtectedRoute>
                <PurchaseThanks />
              </ProtectedRoute>} />

            <Route path='/account' element={
              <ProtectedRoute>
                <Accountpage />
              </ProtectedRoute>} />

            <Route path='/aurora/:name/:machineid/:reload' element={
              <ProtectedPlugin>
                <Accountpage />
              </ProtectedPlugin>} />

            <Route path='/aurora/create' element={
              <ProtectedPlugin>
                <Licenses />
              </ProtectedPlugin>} />

            <Route path='/aurora/create/:reload' element={
              <ProtectedPlugin>
                <Licenses />
              </ProtectedPlugin>} />

            <Route path='/admin' element={
              <ProtectedPlugin>
                <AdminPage />
              </ProtectedPlugin>} />

            <Route path='/aurora/create/:reload/:licenseid/:numlic' element={
              <ProtectedPlugin>
                <NewLicRefresh />
              </ProtectedPlugin>} />

            <Route path='/aurora/create/fb/:licenseid/:machineid/:numlic' element={
              <ProtectedPlugin>
                <CreateNewLic />
              </ProtectedPlugin>} />

            <Route path='/aurora/lic/:reload/:machineid/:useremail' element={
              <ProtectedPluginFailed>
                <CompletedReload />
              </ProtectedPluginFailed>} />



          </Routes>
        </FastSpringProvider>
      </AuthContextProvider>
    </div>
  )
}

export default App