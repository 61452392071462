import React from 'react'
import { useFastSpring } from './FastSpringContext';

const FastSpringCheckout = () => {
    const { data, products } = useFastSpring();
    const checkout = () => {
        // Check if window object is defined before using it
        if (
            typeof window !== "undefined" &&
            window.fastspring &&
            window.fastspring.builder
        ) {
            // Push the payload to add the product
            window.fastspring.builder.checkout();
        }
    };

    const buyProduct = () => {
        const newProduct = {
          path,
          quantity: 1,
        };
    
        // Create a payload with the new product
        const payload = {
          products: [newProduct],
        };
    
        // Push the payload to add the product
        if (
          typeof window !== "undefined" &&
          window.fastspring &&
          window.fastspring.builder
        ) {
          window.fastspring.builder.push(payload);
        }
      };


    return (
        <div>
            <div onClick={checkout}>
                FastSpringCheckout
            </div>
            {products ? ( // Check if products is defined
                products.slice(0, 3).map(
                    (
                        product // Limit to first two items
                    ) => (
                        <div>
                            {product.path},
                            <div onClick={buyProduct}>
                                buy product
                                </div>
                        </div>
                    )
                )
            ) : (
                <p>No products available</p>
            )}
        </div>
    )
}

export default FastSpringCheckout