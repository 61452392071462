import React from 'react';
import { useInView } from 'react-intersection-observer';
import { AnimatePresence, motion } from 'framer-motion';

import SoundbirdFiles from './Screenshot 2025-01-15 at 15.25.48.png'

import DAWShow from './DAWshow.jpg'
import Cross from './CrossCompSB.jpg'
import LufsMeter from './LufsMeter2.jpg'
import TechStack from './ReactFirebaseJuce.jpg'
import Future from './FutureRoadmap.jpg'
import SBLogo from './SBLogoTS.png'


import './blog.css';
import Header from '../header/Header';

const SoundbirdTech = () => {
    // Intersection Observer refs using rootMargin = '-250px'
    // This makes the heading "in view" when it's ~250px from the top of the viewport
    const [soundbirdRef, soundbirdInView] = useInView({
        rootMargin: '0% 0% -50% 0%',
        threshold: 0,
    });
    const [directDawRef, directDawInView] = useInView({
        rootMargin: '0% 0% -50% 0%',
        threshold: 0,
    });
    const [crossPlatformRef, crossPlatformInView] = useInView({
        rootMargin: '0% 0% -50% 0%',
        threshold: 0,
    });
    const [integratedLufsRef, integratedLufsInView] = useInView({
        rootMargin: '0% 0% -30% 0%',
        threshold: 0,
    });
    const [techArchitectureRef, techArchitectureInView] = useInView({
        rootMargin: '0% 0% -50% 0%',
        threshold: 0,
    });
    const [keyBenefitsRef, keyBenefitsInView] = useInView({
        rootMargin: '0% 0% -50% 0%',
        threshold: 0,
    });
    const [futureRoadmapRef, futureRoadmapInView] = useInView({
        rootMargin: '0% 0% -50% 0%',
        threshold: 0,
    });
    const [whySoundbirdRef, whySoundbirdInView] = useInView({
        rootMargin: '0% 0% -50% 0%',
        threshold: 0,
    });

    // Determine which image to show based on which heading is "in view"
    let currentImage =
        SoundbirdFiles;

    if (soundbirdInView) {
        currentImage =
            SoundbirdFiles;
    }
    if (directDawInView) {
        currentImage =
            DAWShow;
    }
    if (crossPlatformInView) {
        currentImage =
            Cross;
    }
    if (integratedLufsInView) {
        currentImage =
            LufsMeter;
    }
    if (techArchitectureInView) {
        //react firebase react native juce
        currentImage = TechStack;
    }
    if (keyBenefitsInView) {

        currentImage = Future;
    }
    if (futureRoadmapInView) {
        currentImage = SBLogo;
    }
    if (whySoundbirdInView) {
        currentImage = SBLogo;
    }

    return (
        <div>
            <Header page="blog" />
            <div className="blog__container">
                {/* LEFT CONTAINER */}
                <div className="blog__leftcontainer">
                    <h1 className="blog__title">Soundbird</h1>
                    <h2>Technical Report</h2>

                    <div className="blog__contents">
                        {/* ---------------------- SOUND BIRD SECTION ---------------------- */}
                        <h3 ref={soundbirdRef} >
                            Soundbird: Revolutionizing File Sharing for Audio Professionals
                        </h3>
                        <div className="blog__text">
                            Overview Soundbird is an innovative, cross-platform solution
                            that seamlessly integrates web, desktop, and mobile technologies
                            to redefine the file-sharing experience for audio professionals.
                            Designed specifically for producers, mixing engineers, and
                            mastering engineers, Soundbird simplifies the process of
                            sharing, receiving, and managing sound files.
                        </div>
                        <div className="blog__text">
                            With Soundbird, you can effortlessly collaborate with clients
                            while maintaining a streamlined workflow within your preferred
                            digital audio workstation (DAW).
                        </div>
                        <div className="blog__pic">Soundbird Files Pic</div>

                        <h2>Core Features</h2>

                        {/* ---------------------- SEAMLESS FILE SHARING ---------------------- */}
                        <h3>Seamless File Sharing</h3>
                        <div className="blog__text">
                            • Share and receive high-quality audio files with your clients
                            in just a few clicks.
                        </div>
                        <div className="blog__text">
                            • Ensure secure transfers with Firebase’s robust backend infrastructure.
                        </div>

                        {/* ---------------------- DIRECT DAW INTEGRATION ---------------------- */}
                        <h3 ref={directDawRef}>
                            Direct DAW Integration

                        </h3>
                        <div className="blog__text">
                            • Soundbird’s JUCE plugin features an embedded web browser,
                            allowing you to access shared files directly within your DAW.
                        </div>
                        <div className="blog__text">
                            • Drag-and-drop functionality enables quick file imports,
                            reducing friction and saving valuable production time.
                        </div>
                        <div className="blog__doublepic">
                            <div className="blog__pic">Plugin Project Selector</div>
                            <div className="blog__pic">Plugin Plugin</div>
                        </div>

                        {/* ---------------------- CROSS-PLATFORM ACCESSIBILITY ---------------------- */}
                        <h3 ref={crossPlatformRef}>Cross-Platform Accessibility</h3>
                        <div className="blog__text">
                            • Web Application: Built with React and Firebase, the web app
                            offers a user-friendly interface for uploading and managing files.
                        </div>
                        <div className="blog__text">
                            • Mobile Application: Developed using React Native, the mobile
                            app provides on-the-go access to files, ensuring seamless
                            collaboration from anywhere.
                        </div>
                        <div className="blog__text">
                            • JUCE Plugin: Designed as a lightweight extension for DAWs,
                            the plugin integrates flawlessly into your existing workflow.
                        </div>

                        <h3>Professional Workflow Optimization</h3>
                        <div className="blog__text">
                            • Centralized file management for all client projects.
                        </div>
                        <div className="blog__text">
                            • Instant notifications for new uploads and revisions.
                        </div>
                        <div className="blog__text">
                            • Version control to keep track of file updates and changes.
                        </div>

                        <h3>Secure and Scalable Infrastructure</h3>
                        <div className="blog__text">
                            • Firebase ensures end-to-end encryption, secure authentication,
                            and reliable data storage.
                        </div>
                        <div className="blog__text">
                            • Scalability to accommodate both independent creators
                            and large-scale studios.
                        </div>

                        {/* ---------------------- INTEGRATED LUFS METER ---------------------- */}
                        <h3 ref={integratedLufsRef}>Integrated LUFS Meter</h3>
                        <div className="blog__text">
                            • For professionals needing to meet LUFS requirements,
                            Soundbird includes a built-in LUFS meter.
                        </div>
                        <div className="blog__text">
                            • Monitor and ensure your audio files comply with
                            industry standards seamlessly.
                        </div>

                        {/* ---------------------- TECHNICAL ARCHITECTURE ---------------------- */}
                        <h2 ref={techArchitectureRef}>Technical Architecture</h2>
                        <h3>Web Application</h3>
                        <div className="blog__text">
                            • Frontend: React provides a dynamic and responsive user interface.
                        </div>
                        <div className="blog__text">
                            • Backend: Firebase handles user authentication, database management,
                            and file storage with unmatched reliability.
                        </div>
                        <div className="blog__text">
                            • Hosting: Google Cloud Hosting ensures fast and secure content delivery.
                        </div>

                        <h3>Mobile Application</h3>
                        <div className="blog__text">
                            • Framework: React Native enables cross-platform functionality
                            with a native look and feel.
                        </div>
                        <div className="blog__text">
                            • Realtime Updates: Firebase’s real-time database ensures
                            instant synchronization across devices.
                        </div>

                        <h3>JUCE Plugin</h3>
                        <div className="blog__text">
                            • Core Functionality: The plugin leverages JUCE’s web browser component
                            to bring the Soundbird web app directly into the DAW environment.
                        </div>
                        <div className="blog__text">
                            • Cross-DAW Compatibility: Supports leading DAWs including Logic Pro,
                            Ableton Live, Pro Tools, and more
                        </div>

                        {/* ---------------------- KEY BENEFITS ---------------------- */}
                        <h2 ref={keyBenefitsRef}>Key Benefits</h2>
                        <div className="blog__text">
                            • Efficiency: Eliminates the need for external file-sharing platforms,
                            consolidating communication and file management.
                        </div>
                        <div className="blog__text">
                            • Integration: Keeps audio professionals within their creative
                            environment, minimizing workflow interruptions.
                        </div>
                        <div className="blog__text">
                            • Scalability: Suitable for freelancers, boutique studios,
                            and enterprise-level audio production houses.
                        </div>
                        <div className="blog__text">
                            • Security: Protects sensitive audio files with enterprise-grade
                            encryption and controlled access permissions.
                        </div>

                        {/* ---------------------- FUTURE ROADMAP ---------------------- */}
                        <h2 ref={futureRoadmapRef}>Future Roadmap</h2>
                        <div className="blog__text">
                            • Our unique AI powered watermarking solution
                        </div>
                        <div className="blog__text">
                            • Offline Mode: Enable access to files and project management
                            without an active internet connection.
                        </div>
                        <div className="blog__text">
                            • Expanded Plugin Features: Introduce waveform previews and
                            basic editing capabilities directly within the plugin.
                        </div>
                        <div className="blog__text">
                            • Control groups
                        </div>

                        {/* ---------------------- WHY SOUNDBIRD? ---------------------- */}
                        <h2 ref={whySoundbirdRef}>Why Soundbird?</h2>
                        <div className="blog__text">
                            Soundbird is more than just a file-sharing tool; it is a meticulously
                            crafted ecosystem tailored to the unique needs of audio professionals.
                            By bridging the gap between clients and creators, Soundbird empowers
                            users to focus on what matters most: creating exceptional audio
                            experiences. Join the revolution in audio collaboration.
                        </div>
                        <div className="blog__text">
                            Experience the future of music creation with Soundbird.
                        </div>

                        <div className="blog__text" style={{ marginTop: '80px' }}>Supported by the Icelandic Technology Development Fund.</div>
                        <img
                            style={{ filter: 'invert(1)', width: '150px' }}
                            src="https://www.rannis.is/media/taeknithrounarsjodur/small/tthrsj_logo_is_w-transp.png"
                            alt="Rannis Support"
                        />
                    </div>
                </div>

                {/* RIGHT CONTAINER with Crossfade Animation */}
                <div className="blog__rightcontainer">
                    <div style={{ position: 'fixed', width: '400px', height: '300px' }}>
                        <AnimatePresence mode="sync">
                            <motion.img
                                key={currentImage}
                                src={currentImage}
                                alt="Section visual"
                                style={{
                                    position: 'absolute',
                                   
                                    marginLeft: currentImage === LufsMeter
                                        ? '280px'
                                        : currentImage === SBLogo
                                            ? '230px'
                                            : '40px',
                                    height: '25vw',
                                    objectFit: 'cover',
                                }}
                                initial={{
                                    opacity: 0, scale: 0.9, borderRadius: 2,
                                    boxShadow: '0px 0px 0px #03030318',
                                    filter: 'blur(10px)'

                                }}
                                animate={{
                                    opacity: 1, scale: 1, borderRadius: 20,
                                    boxShadow:  currentImage!==SBLogo ? '3px 3px 10px #03030318' : 'none',
                                    filter: 'blur(0px)'
                                }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            />

                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SoundbirdTech;
